@use '../../assets/customizations/customization';
@use '../../assets/scss/site';

.left {
	//border: 1px solid green;
	margin: 0 1em 1em 1em;
	padding-top: 0 !important; 		//overwrite bootstrap
	padding-left: 0 !important; 	//overwrite bootstrap
	padding-right: 0 !important;	//overwrite bootstrap
	padding-bottom: 0 !important;	//overwrite bootstrap
}

.right {
	border: 1px solid customization.$custom-main;
	//border: 1px solid red;
	border-radius: 8px;
	margin: 0 1em 1em 1em;
	padding-top: 1em !important; 		//overwrite bootstrap
	padding-left: 1em !important; 	//overwrite bootstrap
	padding-right: 1em !important;	//overwrite bootstrap
	padding-bottom: 1em !important;	//overwrite bootstrap
}

form#login {
	border: 2px solid customization.$custom-main;
	border-radius: 8px;
	margin: 0;
	padding: 0.6em;
	background-color: #EFEFEF;
}

.lowerCallout {
	//used for the "new user, register now" callout on the bottom of the home page
	text-align: center;
}

@media screen and (min-width: 768px) {


	.left {
		//border: 1px solid blue;
		margin: 0 0 1em 0;
		padding-top: 0 !important; 		   //overwrite bootstrap
		padding-left: 1em !important; 	//overwrite bootstrap
		padding-right: 1em !important;	//overwrite bootstrap
		padding-bottom: 0 !important;	  //overwrite bootstrap
	}
	
	.right {
		border: 1px solid customization.$custom-main;
		//border: 1px solid orange;
		border-radius: 8px;
		margin: 0 0 1em 0;
		padding-top: 1em !important; 		//overwrite bootstrap
		padding-left: 1em !important; 	//overwrite bootstrap
		padding-right: 1em !important;	//overwrite bootstrap
		padding-bottom: 1em !important;	//overwrite bootstrap
	}
	
	form#login {
		border: 2px solid customization.$custom-main;
		border-radius: 8px;
		margin: 0;
		padding: 0.6em;
		background-color: #EFEFEF;
	}

	.lowerCallout {
		//used for the "new user, register now" callout on the bottom of the home page
		text-align: center;
	}

}