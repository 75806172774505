@use '../../assets/customizations/customization' as *;

.pagination {
    .page-item {
        &.active .page-link {
            background-color: $custom-main; 
            color: white;
            font-weight: bold;
            border-color: $custom-main;
        }
    }
}
