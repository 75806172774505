@use '../../assets/customizations/customization';

header {
	text-align: center;
	margin: 0;
	padding: 0;
}

header div.client_banner {
	margin: 1em;
	padding: 0;
}

header div.client_banner img {
	max-height: customization.$custom-logoMaxHeight;
}

header div.portal_banner {
	background-color: customization.$custom-main;
}

header nav {
	background-color: customization.$custom-secondary;
	font-weight: 700;
}

.active-link {
	background-color: #fff;
}

header nav a.nav-link.active-link:link {
	color: customization.$custom-activeTxt !important;
	text-decoration: none !important;
}

header nav a.nav-link.active-link:active {
	color: customization.$custom-activeTxt !important;
	text-decoration: none !important;
}

header nav a.nav-link.active-link:hover {
	color: customization.$custom-activeTxt !important;
	text-decoration: none !important;
}

header nav a.nav-link.active-link:visited {
	color: customization.$custom-activeTxt !important;
	text-decoration: none !important;
}

header nav a.nav-link.inactive-link {
	color: customization.$custom-inactiveTxt !important;
}

header nav a.nav-link.inactive-link:link {
	color: customization.$custom-inactiveTxt !important;
}

header nav a.nav-link.inactive-link:active {
	color: customization.$custom-inactiveTxt !important;
}

header nav a.nav-link.inactive-link:hover {
	color: customization.$custom-inactiveTxt !important;
}

header nav a.nav-link.inctive-link:visited {
	color: customization.$custom-inactiveTxt !important;
}
