/* GLOBAL STYLES */

@use '../customizations/customization';

@mixin button-styles {
	background-color: customization.$custom-main !important;
	border-color: customization.$custom-main !important;
}

//The main content containers

main {
	//border: 1px solid red;
	margin: 1em auto;
	padding: 0.3 1em;
}

main.home {
	//border: 1px solid green;
	max-width: 960px;
}

main.content {
	//border: 1px solid orange;
	max-width: 820px;
}

main.form {
	//border: 1px solid purple;
	max-width: 820px;
}

main.fhirData {
	//border: 1px solid orchid;
	overflow: auto;
	height: auto;
	//max-width: 960px;
}

//Default link behavior

a:link {
	color: #000 !important; //overwrite Bootstrap
	text-decoration: underline !important; //overwrite Bootstrap
}

a:visited {
	color: #000 !important; //overwrite Bootstrap
	text-decoration: underline !important; //overwrite Bootstrap
}

a:hover {
	color: #000 !important; //overwrite Bootstrap
	text-decoration: none !important; //overwrite Bootstrap
}

a:active {
	color: #000 !important; //overwrite Bootstrap
	text-decoration: none !important; //overwrite Bootstrap
}

// Unified and prettier H2 or H3 headings

.headerText {
	font-size: 2.4em;
	font-weight: 600;
}

// Forms

form label {
	font-weight: 600;
}

form button.custom {
	border: customization.$custom-main;
	background-color: customization.$custom-main;
}

form button.custom:hover {
	background-color: customization.$custom-secondary;
}

form button.custom:disabled {
	background-color: customization.$custom-secondary;
}

form div.custom {
	margin: 0 0 1em 0;
	padding: 0;
}

form.custom label {
	margin: 0 1em 0 0;
}

form.custom input {
	margin: 0 1em 0 0;
}

form.custom p {
	margin: 1em 0;
}

// General display Containers

// FHIR data display and any account pages "My Account" "Member Account", etc.
.dataContainer {
	border: 1px solid customization.$custom-main;
	border-radius: 8px;
	padding: 1em;
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: normal;
}

// Text Callout
.calloutContainer {
	border: 1px solid customization.$custom-main;
	border-radius: 8px;
	margin: 0 0 1em 0;
	padding: 0.6em 1em;
}

// Form contaner; used for both registration forms - member and developer; login form is different
.formContainer {
	border: 1px solid customization.$custom-main;
	border-radius: 8px;
	margin: 0 0 1em 0;
	padding: 1em;
}

.detail-column {
	width: 20%;
	white-space: nowrap;
}

.app-button {
	@include button-styles;
}

.restore-button {
	@include button-styles;

	&:disabled {
		background-color: grey !important;
		border-color: grey !important;
	}
}

.pagination-button {
	border-width: 1px;
	border-style: solid;
}

// For the metadata and provenance

.footer {
	font-size: .9em;
	//order: 1px solid red;
}

.footer hr {
	border-color: #000;
}

.footer h6 {
	font-size: 1.1em;
	font-weight: bold;
}

// Data display

//Spacing between items

.dataContainer dt {
	margin-bottom: 0.55em;
}

.dataContainer dd {
	margin-bottom: 0.55em;
}

.footer dt {
	margin-bottom: 0.3em;
}

.footer dd {
	margin-bottom: 0.3em;
}

.dark-gray-text {
	color: #333;
}

.reference-popover {
	white-space: nowrap !important;
	min-width: fit-content !important;
}

.comma-separated>div::after {
	content: ",\00a0";
}

.comma-separated>div:last-child::after {
	content: "";
}