.agent-info {
    display: flex;
    flex-direction: column;
}

.agent-info span {
    margin-right: 10px;
}

.agent-info p {
    margin: 0;
}