.qr-code {
  width: 150px;
}

.type-pair {
  display: inline-block;
  min-width: 50%;
}

.element-label {
  margin-top: 1rem;
}

@media (max-width: 768px) {
  .qr-code {
    width: fit-content;
    padding: 0 2rem;
  }
  .type-pair {
    display: block;
    width: 100%;
  }
  .element-label {
    display:inline-block;
    margin-right: 5px;
    margin-top: .5rem;
  }
}