@use '../../../assets/customizations/customization';


.outline-searchBtn {
    background-color: #ffffff !important;
    border-color: customization.$custom-main !important;
    color: customization.$custom-main !important;
}

.outline-searchBtn:hover {
    background-color: customization.$custom-main !important;
    color: #ffffff !important;
}