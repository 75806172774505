@use '../../../assets/customizations/customization';


.termsContainer {
    margin-top: 15px;
    height: 45vh;
    overflow: auto;
    color: customization.$custom-main
}

.modalBtn {
    width: 6em
}