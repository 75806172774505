@use '../../assets/customizations/customization';


footer {
    text-align: center;
    margin: 0;
    padding: 0;
}

// Developer Link
footer h3 {
    background-color: customization.$custom-secondary;
    font-size: 1em;
    margin: 0;
    padding: 0.6em 0;
}

footer h3 a {
    color: customization.$custom-inactiveTxt !important;
}

footer h3 a:link {
    color: customization.$custom-inactiveTxt !important;
}

footer h3 a:active {
    color: customization.$custom-inactiveTxt !important;
}

footer h3 a:hover {
    color: customization.$custom-inactiveTxt !important;
}

footer h3 a:visited {
    color: customization.$custom-inactiveTxt !important;
}


// Footer Navigation
footer nav {
    font-size: 0.9em;
}

footer nav ul {
    margin: 1em 0;
    padding: 0;
    list-style: none;
}

@media screen and (min-width: 768px) {
    footer nav ul {
        margin: 1.2em 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: center;
    }

    footer nav ul li {
        margin: 0 1em 0 0;
        padding: 0;
    }
}

